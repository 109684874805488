<template>
  <b-card
    no-body
    class="vue-json-query-builder"
    :class="{ 'iq-border-color': isVisible }"
    :style="{ 'pointer-events': false ? 'none' : 'all' }"
  >
    <b-card-header
      header-text-variant="white"
      header-class="vue-json-query-builder-header iq-bg min-h-45px px-4 d-flex align-items-center"
      @click="isVisible = !isVisible"
    >
      <small class="mr-auto">Action Builder</small>

      <b-icon-caret-down-fill v-if="isVisible" />
      <b-icon-caret-up-fill v-else />
    </b-card-header>
    <b-card-body class="p-2" v-if="isVisible">
      <ActionGroup
        :current-query="query"
        :options="options"
        :operators="operators"
        :userId="userId"
        ref="actionGroup"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import ActionGroup from "@/own/components/orderManagement/rules/actionBuilder/ActionGroup.vue";
export default {
  name: "ActionBuilder",
  components: {
    ActionGroup,
  },
  props: {
    query: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    operators: {
      type: Object,
      required: true,
    },
    userId: {
      required: false,
    },
  },
  data: function () {
    return {
      isVisible: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.vue-json-query-builder {
  font-size: 12px;
  user-select: none;
  * {
    font-size: 15px !important;
  }
  .vue-json-query-builder-header {
    cursor: pointer;
  }
}
</style>
