<template>
  <div>
    <div class="d-flex align-center mb-4 h-35px px-1">
      <b-button-group class="ml-auto" size="sm">
        <button @click="addRule" class="btn btn-outline-info">
          <b-icon-diamond /> Add Action
        </button>
      </b-button-group>
    </div>
    <template v-for="(item, index) in currentQuery">
      <Action
        :rule="item"
        :options="options"
        :operators="operators"
        :userId="userId"
        :key="index"
        class="mt-2 px-1"
        ref="action"
      />
    </template>
  </div>
</template>

<script>
import Action from "@/own/components/orderManagement/rules/actionBuilder/Action.vue";
export default {
  name: "ActionGroup",
  components: { Action },
  props: {
    currentQuery: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
    },
    operators: {
      type: Object,
      required: true,
    },
    userId: {
      required: false,
    },
  },
  data: function () {
    return {};
  },
  mounted: function () {
    this.$nextTick(() => {
      if (this.currentQuery.length === 0) {
        this.addRule();
      }
    });
  },
  computed: {},
  methods: {
    addRule() {
      const newGroup = {
        id: this.options[0].index,
        value: null,
      };
      this.currentQuery.push(newGroup);
      this.$nextTick(() => {
        this.$refs.action.forEach((comp) => {
          comp.$v.$touch();
        });
      });
    },
  },
};
</script>
